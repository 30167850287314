<template>
	<div class="user-sidebar">
		<el-menu class="el-menu-vertical-demo" :router="true" :unique-opened="true" :default-active="$route.path"
		         background-color="#ffffff" text-color="#333333" active-text-color="#D9262C">
			<template v-for="(item, index) in sideBarData">
				<template v-if="user.company_admin_id <= 0 || (rules[index] && rules[index].has_permission > 0)">
					<el-submenu v-if="item.submenu && item.submenu.length > 0" :key="index" :index="index.toString()">
						<template slot="title">
							<span :class="['icon', 'iconfont', item.icon]"></span>
							<span>{{ item.title }}</span>
						</template>
						<el-menu-item v-for="(val, key) in item.submenu" :key="key" :index="val.path || index + '-' + key">{{
								val.title
							}}
						</el-menu-item>
					</el-submenu>
					<el-menu-item v-else :key='index' :index="item.path">
						<template slot="title">
							<span :class="['icon', 'iconfont', item.icon]"></span>
							<span>{{ item.title }}</span>
						</template>
					</el-menu-item>
				</template>
			</template>
		</el-menu>
	</div>
</template>
<script>
import {permission} from '@/api/user.js'

export default {
	data() {
		return {
			currentPath: '/user/account/company',
			opends: ['5', '5-0'],
			sideBarData: [
				{
					title: '账户信息',
					icon: 'icon-user',
					submenu: [
						{title: '公司信息', path: '/user/account/company'},
						{title: '成员列表', path: '/user/account/member'},
					]
				},
				{title: '我的消息', icon: 'icon-xiaoxi', path: '/user/message'},
				{
					title: '我的订单',
					icon: 'icon-tubiao_dingdan',
					path: '/user/order'
					// submenu: [
					//   { title: '所有订单',path: '/user/order'},
					//   { title: '待付款' },
					//   { title: '待发货' },
					//   { title: '待收货' },
					//   { title: '已取消' },
					//   { title: '已完成' },
					//   { title: '售后' }
					// ]
				},
				{title: '我的收藏', icon: 'icon-shoucang1', path: '/user/collect'},
				// {
				//   title: '我的积分',
				//   icon: 'icon-jifen',
				//   submenu: [
				//     { title: '我的积分' },
				//     { title: '积分明细' }
				//   ]
				// },
				// {
				//   title: '我的分销',
				//   icon: 'icon-fenxiao',
				//   submenu: [
				//     { title: '我的分销' },
				//     { title: '我的上下级' },
				//     { title: '体现管理' }
				//   ]
				// },
				{title: '我的报价单', icon: 'icon-tubiao_mianfeibaojia', path: '/user/quote'},
				{
					title: '我的购物车', icon: 'icon-03',
					path: '/user/shopping'
				},
				// {
				//   title: '退换货服务',
				//   icon: 'icon-tishijingti',
				//   submenu: [
				//     { title: '退货订单' },
				//     { title: '换货订单' }
				//   ]
				// },
				{
					title: '发票管理',
					icon: 'icon-tishijingti',
					submenu: [
						{title: '申请发票', path: '/user/invoice/make'},
						{title: '发票列表', path: '/user/invoice'},
						// { title: '发票信息管理' }
					]
				},
			],
			user: {},
			rules: []
		}
	},
	methods: {
		// selectHandle (path, opends) {
		//   console.log(path, opends)
		//   console.log(this.$route)
		//   this.opends = opends
		//   this.currentPath = path
		//   // if (path && path !== this.$route.path) {
		//   //   this.$router.push({ path: path })
		//   // }
		// }
	},
	created() {
		permission({page_name: 'user_center'}).then(({user, rules}) => {
			this.rules = rules
			this.user = user
		})
	}
}
</script>
<style lang="scss" scoped>
.user-sidebar {
	width: 200px;
	//background-color: #ffffff;
	border-radius: 3px;
	overflow: hidden;
	flex-shrink: 0;

	.el-menu {
		border-right: 0 none;
	}

	.el-submenu__title {
		.iconfont {
			margin-right: 15px;
		}
	}

	.el-submenu .el-menu-item {
		padding: 0 50px !important;
		color: #999;
	}

	.el-menu-item {
		.iconfont {
			margin-right: 15px;
		}

		::v-deep {
			background: #FFFFFF !important;
		}
	}

	.el-menu-item:hover {
		background-color: #f5f5f5 !important;
	}

	::v-deep {
		.el-submenu__title:hover {
			background-color: #f5f5f5 !important;
		}
	}

	.el-menu-vertical-demo {
		height: calc(100% - 62px);
		width: 100%;
	}
}
</style>
