<template>
  <div class="user-index-container">
    <MainHeader />
    <CommonTop :isShowSearch="true" />
    <div class="page-content-wrap">
      <SideBar />
      <div class="content-section">
        <router-view />
      </div>
    </div>
  </div>
</template>
<script>
import MainHeader from '@/components/header/main.vue'
import CommonTop from '@/components/commonTop/index.vue'
import SideBar from '@/components/user/sideBar.vue'
export default {
  components: {
    MainHeader,
    CommonTop,
    SideBar
  },
  data() {
    return {}
  },

}
</script>
<style lang="scss" scoped>
.user-index-container {
  .pointer {
    cursor: pointer;
  }

  background-color: #F5F5F5;

  .page-content-wrap {
    padding: 25px 0;
    display: flex;

    .content-section {
      flex: 1;
      margin-left: 20px;
    }
  }
}
</style>
<style lang="scss">
.user-index-container {
  .primary-color {
    color: #D9262C;
  }

  .module-title {
    height: 18px;
    line-height: 18px;
    margin: 10px auto;
    padding-left: 10px;
    border-left: 4px solid #D9262C;
    font-size: 18px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #222222;
  }

  .pl-pagination {
    text-align: center;
    margin: 30px auto 0 auto;
  }

  .pointer {
    cursor: pointer;
  }

  .pl-flex-start {
    display: flex; //开启 flex
    justify-content: flex-start; //float left
    align-items: center; // 上下方向居中
  }

  .pl-flex {
    display: flex;
    justify-content: space-between; //
    align-items: center;
  }
}</style>
